<template>
  <div class="container">
    <div class="title">API商品</div>
    <div class="content">
      <div
        :class="['goods_type_item',item.status?'':'off_goods_type']"
        v-for="item in goodsTypeList"
        :key="item.type_id"
        @click="clickGoodsType(item)"
      >
        <div
          class="goods_type_img "
          :style="item.pic?'':'background-color: rgb(216, 212, 212);'"
        >
          <img
            v-if="item.pic"
            :src="item.pic"
            alt=""
          >
          <span v-else>暂无图片</span>
        </div>
        <div>{{item.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoodsTypeListApi } from '../../../api/goods'
export default {
  // 组件名称
  name: '',
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    return {
      goodsTypeList: []
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () {
    this.getGoodsTypeList()
  },
  mounted () { },
  // 组件方法
  methods: {
    clickGoodsType (val) {
      if (!val.status) return this.$message.error('该商品已下架')
      this.$router.push({
        path: '/goods/apiGoods/' + val.type_id,
        query: {
          name: val.name
        }
      })
    },
    async getGoodsTypeList () {
      const res = await getGoodsTypeListApi()
      console.log(res)
      if (res && res.code === 0) {
        this.goodsTypeList = res.data.data
      }
    }
  }
}
</script>

<style scoped lang="less">
.container {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  .title {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #ededed;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 35px);
    width: 100%;
    padding: 20px 0 0;
    box-sizing: border-box;
    overflow-y: auto;
    // justify-content: space-around;
    .goods_type_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 450px;
      height: 260px;
      background-color: #edf1fd;
      margin-left: 20px;
      margin-bottom: 20px;
      transition: all 0.1s;
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
      }
      .goods_type_img {
        width: 80px;
        height: 80px;
        margin-bottom: 20px;
        text-align: center;
        line-height: 80px;
        font-size: 14px;
        img {
          height: 100%;
        }
      }
    }
    .off_goods_type {
      background-color: #ededed;
    }
  }
}
</style>
